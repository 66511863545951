import { render, staticRenderFns } from "./RentalVersusPurchase.vue?vue&type=template&id=48b8f528"
import script from "./RentalVersusPurchase.vue?vue&type=script&lang=js"
export * from "./RentalVersusPurchase.vue?vue&type=script&lang=js"
import style0 from "./RentalVersusPurchase.vue?vue&type=style&index=0&id=48b8f528&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports