<template>
    <div :class="$style.wrapper">
        <CityIcon :class="$style.icon" />
        <span aria-label="Totaal aantal woningen">
            <span v-if="!projectStarted">Ongeveer </span>{{ total }} woningen
        </span>
    </div>
</template>

<script>
import CityIcon from '@/assets/icons/city.svg';

export default {
    name: 'TotalObjects',

    components: {
        CityIcon,
    },

    props: {
        apartmentCount: {
            type: Number,
            required: false,
            default: 0,
        },
        singleFamilyHouseCount: {
            type: Number,
            required: false,
            default: 0,
        },
        unknownCount: {
            type: Number,
            required: false,
            default: 0,
        },
        projectStarted: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    computed: {
        total() {
            return this.apartmentCount + this.singleFamilyHouseCount + this.unknownCount;
        },
    },
};
</script>

<style module lang="scss">
    .wrapper {
        align-items: center;
        display: flex;
    }

    .icon {
        width: 20px;
        height: 20px;
        margin-right: 20px;
    }
</style>
