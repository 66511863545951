<template>
    <h3 :class="$style.title">
        <slot />
    </h3>
</template>

<script>
export default {
    name: 'Title',
};
</script>

<style module lang="scss">
    .title {
        font-size: 32px;
        font-weight: 800;
        line-height: 32px;
        margin: 0;
        padding-right: 2rem;
    }
</style>
