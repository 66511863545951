<template>
    <div :class="$style.wrapper">
        <h4 v-if="title" :class="$style.title">
            {{ title }}
        </h4>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'Group',

    props: {
        title: {
            type: String,
            required: false,
            default: undefined,
        },
    },
};
</script>

<style module lang="scss">
    .wrapper {
        padding: 24px;

        &:not(:last-of-type) {
            border-bottom: solid 2px #EDEDED;
        }
    }

    .title {
        color: #91929A;
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        margin: 0 0 1rem 0;
    }
</style>
