<template>
    <div :class="$style.filter">
        <span :class="$style.heading">Selecteer bouwstatus</span>
        <div v-for="status in statuses" :key="status.id" :class="[$style.item, $style['item--'+status.attributes.name.toLowerCase()]]">
            <input :id="status.id" v-model="valueProxy" :value="status.id" type="checkbox" :class="$style.item__input">
            <label :for="status.id" :class="$style.item__label">
                {{ status.attributes.project_status__label }}
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectStatusFilter',
    model: {
        event: 'change',
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        statuses: {
            type: Array,
            required: true,
        },
    },
    computed: {
        valueProxy: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('change', value);
            },
        },
    },
};
</script>

<style module lang="scss">
    @import "~sass-custom-box";

    .filter {
        background-color: #FBFBFC;
        padding: 1.5rem;
    }

    .heading {
        color: #91929A;
        display: block;
        font-weight: 800;
        margin-bottom: 1rem;
        font-size: 1.125rem;
    }

    .item__label {
        cursor: pointer;
    }

    @include set-custom-box-theme((
        container: (
            _checked: (
                background-color: #fff,
                border: 1px solid currentColor
            ),
            _focus: (
                box-shadow: 0 0 0 .125rem #3b99fc
            ),
            background-color: #fff,
            border: 1px solid currentColor,
            border-radius: 1px,
            height: 1rem,
            width: 1rem
        )
    ));

    @include set-custom-box-checkbox-theme((
        tick: (
            background-color: currentColor,
            height: .625rem,
            left: .1875rem,
            top: .1875rem,
            width: .625rem
        )
    ));

    @include custom-box(item) {
        @include custom-box-input();
        @include custom-box-label() {
            height: auto !important;
            line-height: get-custom-box-property('container.height') !important;

            &:after {
                background: get-custom-box-property('tick.background-color');
                border: none;
                transform: scale(0.5);
                transform-origin: center;
            }
        }

        @at-root {
            & > input[type="checkbox"]:checked + #{&}#{$custom-box-element-separator}label {
                &:after {
                    transform: scale(1);
                }
            }
        }

        background: #fff;
        border: solid 1px #EDEDED;
        display: flex;
        height: auto;
        margin: .5rem 0;
        padding: .75rem 1rem;

        &:last-child {
            margin-bottom: 0;
        }

        &--in_ontwikkeling #{&}#{$custom-box-element-separator}label {
            &:before,
            &:after {
                color: #2A5175;
            }
        }

        &--in_voorbereiding #{&}#{$custom-box-element-separator}label {
            &:before,
            &:after {
                color: #FCC100;
            }
        }

        &--studie #{&}#{$custom-box-element-separator}label {
            &:before,
            &:after {
                color: #00BE3E;
            }
        }
    }
</style>
