import Home from '@/views/Home';
import ProjectInformation from '@/views/ProjectInformation';

export default [
    {
        path: '/',
        name: 'Home',
        component: Home,
        children: [
            {
                path: 'projects/:id',
                name: 'ProjectInformation',
                component: ProjectInformation,
            },
        ],
    },
];
