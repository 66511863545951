import Vue from 'vue';
import 'whatwg-fetch';
import App from './App.vue';
import router from './router';
import './tooltip';

import '@/plugins/sentry';

Vue.config.productionTip = false;

new Vue({
    router,
    render: h => h(App),
}).$mount('#bouwkaart');
