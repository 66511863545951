<template>
    <div :class="$style.description">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'Description',
};
</script>

<style module lang="scss">
    .description {
        margin-top: 1rem;
    }
</style>
