<template>
    <div :class="$style['progress-bar']">
        <div :class="$style['progress-bar__header']">
            <div>
                {{ label }}
                <span v-if="tooltip" v-tooltip="tooltip" :class="$style['progress-bar__info']">
                    <InformationIcon :class="$style['progress-bar__info-icon']" />
                </span>
            </div>
            <div :class="$style['progress-bar__description']">
                {{ value }} woningen ({{ progress }}%)
            </div>
        </div>
        <div :class="$style['progress-bar__bar']">
            <div :class="$style['progress-bar__progress']" :style="{ width: progress + '%', backgroundColor: color }" />
        </div>
    </div>
</template>

<script>
import InformationIcon from '@/assets/icons/information.svg';

export default {
    name: 'ProgressBar',

    components: {
        InformationIcon,
    },

    props: {
        label: {
            type: String,
            required: true,
        },
        tooltip: {
            type: String,
            default: null,
        },
        total: {
            type: Number,
            required: true,
        },
        value: {
            type: Number,
            default: 0,
        },
        color: {
            type: String,
            default: '#000',
        },
    },

    computed: {
        progress() {
            if (this.total === 0) {
                return 0;
            }

            return Math.round(this.value / this.total * 100);
        },
    },
};
</script>

<style module lang="scss">
    .progress-bar {

    }

    .progress-bar__header {
        display: flex;
        justify-content: space-between;
        margin-top: 0.75rem;
    }

    .progress-bar__info {
        vertical-align: middle;
    }

    .progress-bar__info-icon {
        fill: currentColor;
        height: 1rem;
        width: 1rem;
    }

    .progress-bar__description {
        color: #91929A;
    }

    .progress-bar__bar {
        background: #EDEDED;
        border-radius: 2px;
        height: 0.5rem;
        width: 100%;
        margin-top: 0.25rem;
    }

    .progress-bar__progress {
        border-radius: 2px;
        height: 100%;
    }
</style>
