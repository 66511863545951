import Vue from 'vue';
import * as Sentry from '@sentry/vue';

const apiKey = process.env.VUE_APP_SENTRY_DSN;
if (apiKey) {
    Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
    });
}
