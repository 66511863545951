<template>
    <div :class="$style.wrapper">
        <ClockIcon :class="$style.icon" />
        <span>
            Verwachte start bouw: {{ date }}
        </span>
    </div>
</template>

<script>
import ClockIcon from '@/assets/icons/clock.svg';

export default {
    name: 'StartDate',

    components: {
        ClockIcon,
    },

    props: {
        date: {
            type: [Number, String],
            required: true,
        },
    },
};
</script>

<style module lang="scss">
    .wrapper {
        align-items: center;
        display: flex;
    }

    .icon {
        width: 20px;
        height: 20px;
        margin-right: 16px;
    }
</style>
