<template>
    <div :class="[$style.legend, isExpanded ? $style['legend--expanded'] : '']">
        <button :class="$style.legend__title" @click="toggle()">
            <ArrowIcon :class="[$style.icon, !isExpanded ? $style['icon--upside-down'] : '']" />
            <span v-if="isExpanded">Verberg Legenda</span>
            <span v-else>Toon Legenda</span>
        </button>
        <div :class="[$style.legend__body, isExpanded ? $style['legend__body--visible'] : '']">
            <slot />
        </div>
    </div>
</template>

<script>
import ArrowIcon from '@/assets/icons/arrow.svg';

export default {
    name: 'Legend',
    components: {
        ArrowIcon,
    },
    data() {
        return {
            isExpanded: false,
        };
    },
    methods: {
        toggle() {
            this.isExpanded = !this.isExpanded;
        },
    },
};
</script>

<style module lang="scss">
    .legend {
        background-color: #F8F8F8;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;

        @media (min-width: 576px) {
            left: 1rem;
            max-width: 24rem;
            top: 1rem;
            width: calc(100% - 2rem);
        }

        @media (min-width: 990px) {
            top: 2rem;
        }
    }

    .legend--expanded {
        height: 100%;

        @media (min-width: 576px) {
            height: auto;
        }
    }

    .legend__title {
        align-items: center;
        background-color: #F8F8F8;
        border-bottom: 1px solid #E7E7E7;
        border-left: none;
        border-right: none;
        border-top: none;
        color: inherit;
        display: flex;
        font-size: 0.75rem;
        font-weight: bold;
        justify-content: center;
        padding: 1rem;
        text-align: center;
        text-transform: uppercase;
        width: 100%;

        @media (min-width: 576px) {
            display: none;
        }
    }

    .legend__body {
        display: none;

        @media (min-width: 576px) {
            display: block !important;
        }
    }

    .legend__body--visible {
        display: block;
    }

    .icon {
        fill: currentColor;
        height: 20px;
        margin-right: 8px;
        width: 20px;
    }

    .icon--upside-down {
        transform: rotateX(180deg);
    }
</style>
