<template>
    <div :class="$style.wrapper">
        <LocationIcon :class="$style.icon" />
        <span>
            {{ municipality }}
        </span>
    </div>
</template>

<script>
import LocationIcon from '@/assets/icons/location.svg';

export default {
    name: 'Municipality',

    components: {
        LocationIcon,
    },

    props: {
        municipality: {
            type: String,
            required: true,
        },
    },
};
</script>

<style module lang="scss">
    .wrapper {
        align-items: center;
        display: flex;
    }

    .icon {
        width: 20px;
        height: 20px;
        margin-right: 16px;
    }
</style>
