<template>
    <section v-if="project" :class="$style.detail">
        <Close :class="$style.close" @click.native="close()" />
        <Photo v-if="image" :source="image" :title="title" />
        <Group>
            <Title :style="{'padding-top': !image && '1rem'}">
                {{ title }}
            </Title>
            <div :class="$style.statistics">
                <Municipality v-if="municipality" :municipality="municipality" />
                <TotalObjects :class="$style.statistic"
                              :project-started="!showStart"
                              :apartment-count="project.attributes.type_app"
                              :single-family-house-count="project.attributes.type_egw"
                              :unknown-count="project.attributes.type_unknown"
                />
                <Start v-if="startDate && showStart" :class="$style.statistic" :date="startDate" />
                <RentalVersusPurchase :class="$style.statistic"
                                      :rental-count="rentalCount"
                                      :purchase-count="purchaseCount"
                />
            </div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <Description v-if="description" v-html="description" />
        </Group>
        <Group
            v-if="higherPurchaseCount > 0 || midPurchaseCount > 0 || lowPurchaseCount > 0 || higherRentCount > 0 || midRentCount > 0 || socialRentCount > 0"
            title="Onderverdeling prijscategorie"
        >
            <ProgressBar
                v-if="higherPurchaseCount > 0"
                label="Koop in hoog segment"
                tooltip="vanaf € 355.000"
                :total="totalHouses"
                :value="higherPurchaseCount"
                color="#2A5175"
            />
            <ProgressBar
                v-if="midPurchaseCount > 0"
                label="Koop in middensegment"
                tooltip="€ 210.000 - € 355.000"
                :total="totalHouses"
                :value="midPurchaseCount"
                color="#2A5175"
            />
            <ProgressBar
                v-if="lowPurchaseCount > 0"
                label="Koop in sociaal segment"
                tooltip="tot € 210.000"
                :total="totalHouses"
                :value="lowPurchaseCount"
                color="#2A5175"
            />
            <ProgressBar
                v-if="higherRentCount > 0"
                label="Huur in hoog segment"
                tooltip="vanaf € 1.075"
                :total="totalHouses"
                :value="higherRentCount"
                color="#2A5175"
            />
            <ProgressBar
                v-if="midRentCount > 0"
                label="Huur in middensegment"
                tooltip="€ 763,47 - € 1.075"
                :total="totalHouses"
                :value="midRentCount"
                color="#2A5175"
            />
            <ProgressBar
                v-if="socialRentCount > 0"
                label="Huur in sociaal segment"
                tooltip="tot € 763,47"
                :total="totalHouses"
                :value="socialRentCount"
                color="#2A5175"
            />
            <ProgressBar
                v-if="unkownPriceCount > 0"
                label="Onbekend"
                :total="totalHouses"
                :value="unkownPriceCount"
                color="#2A5175"
            />
            <Description>
                <small>*) Prijsgrenzen nieuwbouwwoningen Rotterdam, peildatum 1-1-2022</small>
            </Description>
        </Group>
        <Group
            v-if="apartmentCount > 0 || singleFamilyHouseCount > 0"
            title="Onderverdeling type woning"
        >
            <ProgressBar
                v-if="apartmentCount > 0"
                label="Appartementen"
                :total="totalHouses"
                :value="apartmentCount"
                color="#FCC100"
            />
            <ProgressBar
                v-if="singleFamilyHouseCount > 0"
                label="Eengezinswoningen"
                :total="totalHouses"
                :value="singleFamilyHouseCount"
                color="#FCC100"
            />
            <ProgressBar
                v-if="unknownHouseTypeCount > 0"
                label="Onbekend type"
                :total="totalHouses"
                :value="unknownHouseTypeCount"
                color="#FCC100"
            />
        </Group>
        <Group
            v-if="developers"
            title="Extra informatie"
        >
            <Description>
                Initiatiefnemers: {{ developers }}
            </Description>
        </Group>
    </section>
</template>

<script>
import ky from 'ky';
import Close from '@/components/ProjectInformation/Close';
import Description from '@/components/ProjectInformation/Description';
import Group from '@/components/ProjectInformation/Group';
import Photo from '@/components/ProjectInformation/Photo';
import RentalVersusPurchase from '@/components/ProjectInformation/RentalVersusPurchase';
import Start from '@/components/ProjectInformation/Start';
import Title from '@/components/ProjectInformation/Title';
import TotalObjects from '@/components/ProjectInformation/TotalObjects';
import ProgressBar from '@/components/ProjectInformation/ProgressBar';
import Municipality from '@/components/ProjectInformation/Municipality';

export default {
    name: 'Detail',

    components: {
        Municipality,
        ProgressBar,
        Close,
        Description,
        Group,
        Photo,
        RentalVersusPurchase,
        Start,
        Title,
        TotalObjects,
    },

    data() {
        return {
            file: undefined,
            project: undefined,
        };
    },

    computed: {
        image() {
            const url = this.file?.links?.small?.href || this.file?.attributes?.uri?.url;

            if (!url) {
                return undefined;
            }

            return url.startsWith('http') ? url : process.env.VUE_APP_API_ORIGIN + url;
        },

        title() {
            return this.project.attributes.title;
        },

        link() {
            return this.project.attributes?.link?.uri;
        },

        description() {
            return this.project.attributes?.body?.processed;
        },

        startDate() {
            return this.project.attributes.starting_date;
        },

        showStart() {
            return this.project.attributes.status_building < 1;
        },

        rentalCount() {
            return this.project.attributes.sale_rent_higher +
                this.project.attributes.sale_rent_mid +
                this.project.attributes.sale_rent_social;
        },

        purchaseCount() {
            return this.project.attributes.sale_buy_higher +
                this.project.attributes.sale_buy_low +
                this.project.attributes.sale_buy_mid;
        },

        municipality() {
            return this.project.attributes.municipality ?? undefined;
        },

        developers() {
            let developers = this.project.attributes?.developer;

            if (!developers) {
                return undefined;
            }

            return developers
                .split(';')
                .join(', ');
        },

        higherRentCount() {
            return this.project.attributes.sale_rent_higher;
        },

        higherPurchaseCount() {
            return this.project.attributes.sale_buy_higher;
        },

        midRentCount() {
            return this.project.attributes.sale_rent_mid;
        },

        midPurchaseCount() {
            return this.project.attributes.sale_buy_mid;
        },

        socialRentCount() {
            return this.project.attributes.sale_rent_social;
        },

        lowPurchaseCount() {
            return this.project.attributes.sale_buy_low;
        },

        unkownPriceCount() {
            return this.project.attributes.sale_unknown;
        },

        totalHouses() {
            return this.apartmentCount + this.singleFamilyHouseCount + this.unknownHouseTypeCount;
        },

        singleFamilyHouseCount() {
            return this.project.attributes.type_egw;
        },

        apartmentCount() {
            return this.project.attributes.type_app;
        },

        unknownHouseTypeCount() {
            return this.project.attributes.type_unknown;
        },
    },

    watch: {
        $route() {
            this.fetchProject();
        },
    },

    created() {
        this.fetchProject();

        const listener = (event) => {
            if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
                this.close();
            }
        };
        document.addEventListener('keydown', listener);
        this.$once('hook:beforeDestroy', () => {
            document.removeEventListener('keydown', listener);
        });
    },

    methods: {
        close() {
            this.$router.push({ name: 'Home' });
        },

        getMunicipalityTitle(data) {
            let municipalityId = this.project.relationships.municipality.data.id;
            let municipality = data.included.find(municipality => municipality.id === municipalityId);

            return municipality.attributes.title;
        },

        async fetchProject() {
            ky.get(`${process.env.VUE_APP_API_BASE_URL}project/${this.$route.params.id}`)
                .json()
                .then(response => {
                    this.project = response.data;
                    this.project.attributes['municipality'] = this.getMunicipalityTitle(response);
                    this.file = undefined;
                    if (response.included) {
                        this.file = response.included.find(include => include.type === 'file');
                    }
                })
                .catch(error => {
                    alert('Oeps, het is niet gelukt om het bouwproject te laden. Ververs de pagina en probeer het opnieuw.');
                    // eslint-disable-next-line no-console
                    console.error(error);
                });
        },
    },
};
</script>

<style module lang="scss">
    .detail {
        background-color: white;
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.16);
        height: calc(100% - 2rem);
        left: 1rem;
        max-width: 30rem;
        overflow-y: auto;
        position: absolute;
        top: 1rem;
        width: calc(100% - 2rem);
        z-index: 1;

        @media (min-width: 576px) {
            height: 100%;
            left: 0;
            top: 0;
        }
    }

    .close {
        position: absolute;
        right: 0;
        top: 0;

        @media (min-width: 1550px) {
            top: 1rem;
        }
    }

    .neighborhood {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 14px;
    }

    .statistics {
        margin-top: 24px;
        color: #91929A;
    }

    .statistic {
        margin-top: 11px;
        fill: #91929A;
    }

</style>
