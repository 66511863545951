<template>
    <div :class="$style.wrapper">
        <StatsIcon :class="$style.icon" />
        <span aria-label="Percentage huur- versus koopwoningen">
            {{ rentPercentage }}% huur / {{ purchasePercentage }}% koop
        </span>
    </div>
</template>

<script>
import StatsIcon from '@/assets/icons/stats.svg';

export default {
    name: 'RentalVersusPurchase',

    components: {
        StatsIcon,
    },

    props: {
        rentalCount: {
            type: Number,
            required: false,
            default: 0,
        },
        purchaseCount: {
            type: Number,
            required: false,
            default: 0,
        },
    },

    computed: {
        totalCount() {
            return this.rentalCount + this.purchaseCount;
        },

        rentPercentage() {
            if (this.totalCount === 0) {
                return 0;
            }

            return Math.floor((this.rentalCount / this.totalCount) * 100);
        },

        purchasePercentage() {
            if (this.totalCount === 0) {
                return 0;
            }

            return Math.ceil((this.purchaseCount / this.totalCount) * 100);
        },
    },
};
</script>

<style module lang="scss">
    .wrapper {
        align-items: center;
        display: flex;
    }

    .icon {
        width: 20px;
        height: 20px;
        margin-right: 16px;
    }
</style>
