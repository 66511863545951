<script>
export default {
    functional: true,
    props: {
        colors: {
            type: Array,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        size: {
            type: Array,
            default() {
                return [36, 36];
            },
        },
        title: {
            type: String,
            default: null,
        },
    },
    render(h, context) {
        const occurrence = context.props.colors.reduce((acc, color) => {
            acc[color] = (acc[color] || 0) + 1;

            return acc;
        }, {});

        const slices = Object.keys(occurrence).map(color => {
            return {
                fraction: occurrence[color] / context.props.colors.length,
                color,
            };
        });

        let cumulativeFraction = 0;

        function getCoordinatesForFraction(fraction) {
            const x = Math.cos(2 * Math.PI * fraction);
            const y = Math.sin(2 * Math.PI * fraction);

            return [x, y];
        }

        const paths = slices.map(slice => {
            const [startX, startY] = getCoordinatesForFraction(cumulativeFraction);

            // each slice starts where the last slice ended, so keep a cumulative fraction
            cumulativeFraction += slice.fraction;

            const [endX, endY] = getCoordinatesForFraction(cumulativeFraction);

            // if the slice is more than 50%, take the large arc (the long way around)
            const largeArcFlag = slice.fraction > 0.5 ? 1 : 0;

            // create an array and join it just for code readability
            const pathData = [
                `M ${startX} ${startY}`, // Move
                `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
                `L 0 0`, // Line
            ].join(' ');

            return {
                d: pathData,
                fill: slice.color,
            };
        });

        return h(
            'div',
            {
                attrs: {
                    'aria-label': context.props.title,
                },
                class: {
                    [context.$style.icon]: true,
                },
            },
            [
                h(
                    'svg',
                    {
                        attrs: {
                            xmlns: 'http://www.w3.org/2000/svg',
                            viewBox: '-1 -1 2 2',
                        },
                        class: {
                            [context.$style.svg]: true,
                        },
                    },
                    paths.length === 1 ? [
                        h(
                            'circle',
                            {
                                attrs: {
                                    cx: '0',
                                    cy: '0',
                                    r: '1',
                                    fill: paths[0].fill,
                                },
                            },
                        ),
                    ] : paths.map((path, index) => {
                        return h(
                            'path',
                            {
                                attrs: {
                                    d: path.d,
                                    fill: path.fill,
                                },
                                key: index,
                            },
                        );
                    }),
                ),
                h(
                    'div',
                    {
                        class: {
                            [context.$style.text]: true,
                        },
                    },
                    [
                        h(
                            'span',
                            {
                                style: {
                                    fontSize: ((context.props.size[0] + context.props.size[1]) / 2 / 3) + 'px',
                                },
                            },
                            context.props.text,
                        ),
                    ],
                ),
            ],
        );
    },
};
</script>

<style module lang="scss">
    .icon {
        background-color: white;
        border-radius: 50%;
        height: 100%;
        position: relative;
        width: 100%;
        z-index: 1;
    }

    .svg {
        height: 100%;
        transform: rotate(-90deg);
        transition: transform .1s ease-in-out;
        width: 100%;

        :global(.leaflet-marker-icon:focus) &,
        :global(.leaflet-marker-icon:hover) & {
            transform: rotate(-90deg) scale(1.15);
        }
    }

    .text {
        align-items: center;
        background-color: white;
        border-radius: 50%;
        bottom: 0;
        box-sizing: border-box;
        display: flex;
        font-weight: bold;
        justify-content: center;
        left: 0;
        margin: 6px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 300; // N.B. Leaflet adds a z-index of 200 to every svg, so our z-index must be higher!
    }
</style>
