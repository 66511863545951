<template>
    <div :class="$style.image" :style="{ backgroundImage }" />
</template>

<script>
export default {
    name: 'Photo',

    props: {
        source: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },

    computed: {
        backgroundImage() {
            return 'url(' + this.source + ')';
        },
    },
};
</script>

<style module lang="scss">
    .image {
        display: block;
        height: 254px;
        background-size: cover;
        width: 100%;
    }
</style>
