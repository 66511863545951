<template>
    <div :class="$style.map" />
</template>

<script>
import L from 'leaflet';
import 'leaflet.locatecontrol';
import 'leaflet.markercluster';
import Vue from 'vue';
import ProjectIcon from '@/components/ProjectIcon.vue';
import CrosshairIcon from '@/assets/icons/crosshair.svg';

/* This code is needed to properly load the images in the Leaflet CSS */
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const COLORS = {
    in_ontwikkeling: '#2A5175',
    in_voorbereiding: '#FCC100',
    studie: '#00BE3E',
};

export default {
    name: 'Map',
    props: {
        projects: {
            type: Array,
            required: true,
        },
    },
    computed: {
        markers() {
            return this.projects
                .filter(project => project.attributes.geolocation)
                .map(project => {
                    const colors = [
                        ...(new Array(Number(project.attributes.status_building))).fill(COLORS.in_ontwikkeling),
                        ...(new Array(Number(project.attributes.status_preparing))).fill(COLORS.in_voorbereiding),
                        ...(new Array(Number(project.attributes.status_study))).fill(COLORS.studie),
                    ];
                    const amount = project.attributes.status_building + project.attributes.status_study + project.attributes.status_preparing;

                    const marker = L.marker([project.attributes.geolocation.lat, project.attributes.geolocation.lon], { colors, amount });
                    marker.bindTooltip(project.attributes.title);
                    marker.setIcon(this.getIcon(colors, String(amount), project.attributes.title));

                    marker.on('click', () => {
                        this.$router.push({
                            name: 'ProjectInformation',
                            params: {
                                id: project.id,
                            },
                        }).catch(() => {});
                    });
                    // Open popup on enter
                    marker.on('keypress', (event) => {
                        if (event.originalEvent.key === 'Enter' || event.originalEvent.keyCode === 13) {
                            marker.fire('click', {
                                layer: event.layer,
                            });
                        }
                    });

                    return marker;
                });
        },
    },
    watch: {
        markers: 'addMarkersToMap',
    },
    mounted() {
        this.$options.map = L.map(this.$el, {
            dragging: !L.Browser.mobile,
            tap: !L.Browser.mobile,
            zoomControl: false,
        }).setView([51.928021277942, 4.490406300000018], 11);
        this.$options.map.addControl(
            L.control.zoom({
                position: 'bottomright',
            }),
        );
        this.$options.map.addControl(
            L.control.locate({
                position: 'bottomright',
                icon: this.$style['locate'],
                iconLoading: this.$style['locate'] + ' ' + this.$style['locate--acquiring'],
                createButtonCallback: (container, options) => {
                    const link = L.DomUtil.create('a', 'leaflet-bar-part leaflet-bar-part-single', container);
                    link.title = options.strings.title;

                    const instance = new Vue({
                        render(h) {
                            return h(CrosshairIcon, {class: {[options.icon]: true}});
                        },
                    });
                    const icon = instance.$mount().$el;
                    link.appendChild(icon);

                    return {link, icon};
                },
                strings: {
                    title: 'Toon me waar ik ben',
                    metersUnit: 'meter',
                    feetUnit: 'voet',
                    popup: 'Je bent binnen {distance} {unit} van dit punt',
                    outsideMapBoundsMsg: 'Je lijkt buiten de grenzen van onze kaart te zijn',
                },
                showPopup: false,
                onLocationOutsideMapBounds: () => {},
                onLocationError: () => {},
                locateOptions: {
                    maxZoom: 15,
                },
            }),
        );

        const backgroundLayer = L.tileLayer('https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0/pastel/EPSG:3857/{z}/{x}/{y}.png', {
            attribution: 'Kaartgegevens &copy; <a href="https://www.kadaster.nl">Kadaster</a>',
            minZoom: 7,
            maxZoom: 19,
        });
        this.$options.map.addLayer(backgroundLayer);

        this.$options.cluster = this.getCluster();
        this.$options.map.addLayer(this.$options.cluster);

        this.addMarkersToMap(this.markers);
    },
    methods: {
        addMarkersToMap(newValue, oldValue = []) {
            this.$options.cluster.removeLayers(oldValue);
            this.$options.cluster.addLayers(newValue);
        },
        getCluster() {
            let cluster = L.markerClusterGroup({
                showCoverageOnHover: false,
                removeOutsideVisibleBounds: true,
                chunkedLoading: true,
                animateAddingMarkers: false,
                iconCreateFunction: (cluster) => {
                    const childMarkers = cluster.getAllChildMarkers();

                    const amount = childMarkers.reduce((acc, marker) => acc + marker.options.amount, 0);
                    const title = `Cluster met ${childMarkers.length} locaties, klik om alle locaties te zien`;

                    cluster.bindTooltip(title);

                    return this.getIcon(
                        childMarkers.flatMap(marker => marker.options.colors),
                        amount > 10000 ? `${Math.round(amount / 1000)}k` : String(amount),
                        title,
                    );
                },
            });

            // Zoom to cluster (or spiderify) on enter
            cluster.on('clusterkeypress', (event) => {
                if (event.originalEvent.key === 'Enter' || event.originalEvent.keyCode === 13) {
                    cluster.fire('click', {
                        layer: event.layer,
                    });
                }
            });

            return cluster;
        },
        getIcon(colors, text, title) {
            const size = [48, 48];
            const instance = new Vue({
                render(h) {
                    return h(ProjectIcon, {
                        props: {
                            colors,
                            text,
                            size,
                            title,
                        },
                    });
                },
            });
            const icon = instance.$mount();

            return L.divIcon(
                {
                    html: icon.$el.outerHTML,
                    iconSize: L.point(size),
                    className: this.$style.icon,
                },
            );
        },
    },
    map: null,
    cluster: null,
};
</script>

<style src="leaflet/dist/leaflet.css" />
<style src="leaflet.locatecontrol/dist/L.Control.Locate.css" />
<style src="leaflet.markercluster/dist/MarkerCluster.css" />
<style lang="scss">
    .leaflet-bar a {
        &:focus,
        &:hover {
            background-color: #eaeaea;
        }
    }
</style>

<style module lang="scss">
    .map {
        height: 100%;
        width: 100%;
    }

    .icon {
        // We need to have a class to remove the default Leaflet class that adds unwanted styling.
    }

    .locate {
        fill: black;
        height: 22px;
        margin: 4px;
        width: 22px;

        &--acquiring {
            animation: acquiring-animation 1s steps(1) infinite;
        }
    }

    @keyframes acquiring-animation {
        0% {
            opacity: 1;
        }

        50% {
            opacity: .5;
        }

        100% {
            opacity: 1;
        }
    }
</style>
