<template>
    <button aria-label="Sluiten" :class="$style.close">
        <CloseIcon :class="$style.icon" />
    </button>
</template>

<script>
import CloseIcon from '@/assets/icons/close.svg';

export default {
    name: 'Close',

    components: {
        CloseIcon,
    },
};
</script>

<style module lang="scss">
    .close {
        align-items: center;
        background-color: black;
        border: 0;
        color: white;
        display: flex;
        height: 40px;
        justify-content: center;
        margin: 0;
        padding: 0;
        width: 40px;
    }

    .icon {
        fill: white;
        height: 24px;
        width: 24px;
    }
</style>
