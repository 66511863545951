<template>
    <div :class="$style.page">
        <Map :class="$style.map" :projects="projectsFiltered" />
        <Legend>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-if="mapDescription" :class="$style.description" v-html="mapDescription" />
            <ProjectStatusFilter v-model="activeProjectStatuses" :statuses="projectStatuses" />
        </Legend>
        <router-view />
    </div>
</template>

<script>
import ky from 'ky';
import Legend from '@/components/Legend';
import Map from '@/components/Map.vue';
import ProjectStatusFilter from '@/components/ProjectStatusFilter.vue';

export default {
    name: 'Home',
    components: {
        Legend,
        Map,
        ProjectStatusFilter,
    },
    data() {
        return {
            projects: [],
            projectStatuses: [],
            municipalities: [],
            activeProjectStatuses: [],
            mapDescription: null,
        };
    },
    computed: {
        projectsFiltered() {
            if (this.projectStatuses.length === 0) {
                // Statuses aren't loaded yet, just return all projects.
                return this.projects;
            }

            const statusBuildingActive = this.activeProjectStatuses.includes(this.projectStatusesMapped.in_ontwikkeling.id);
            const statusStudyActive = this.activeProjectStatuses.includes(this.projectStatusesMapped.studie.id);
            const statusPreparingActive = this.activeProjectStatuses.includes(this.projectStatusesMapped.in_voorbereiding.id);

            return this.projects
                .map(project => {
                    return {
                        ...project,
                        attributes: {
                            ...project.attributes,
                            status_building: statusBuildingActive ? project.attributes.status_building : null,
                            status_study: statusStudyActive ? project.attributes.status_study : null,
                            status_preparing: statusPreparingActive ? project.attributes.status_preparing : null,
                        },
                    };
                })
                .filter(project => {
                    return project.attributes.status_building + project.attributes.status_study + project.attributes.status_preparing > 0;
                });
        },
        projectStatusesMapped() {
            return this.projectStatuses.reduce((acc, status) => {
                return { ...acc, [status.attributes.name.toLowerCase()]: status };
            }, {});
        },
    },
    created() {
        this.fetchMapDescription();
        this.fetchProjects();
        this.fetchProjectStatuses();
    },
    methods: {
        fetchProjects() {
            return ky.get(process.env.VUE_APP_API_BASE_URL + 'project?page[limit]=1000&fields[project]=geolocation,status_building,status_preparing,status_study,title&include=')
                .json()
                .then(data => {
                    this.projects = data.data;
                })
                .catch(error => {
                    alert('Oeps, het is niet gelukt om de bouwprojecten te laden. Ververs de pagina en probeer het opnieuw.');
                    // eslint-disable-next-line no-console
                    console.error(error);
                });
        },
        fetchProjectStatuses() {
            return ky.get(process.env.VUE_APP_API_BASE_URL + 'project_status')
                .json()
                .then(data => {
                    this.projectStatuses = data.data;
                    this.activeProjectStatuses = data.data.map(status => status.id);
                })
                // eslint-disable-next-line no-console
                .catch(console.error);
        },
        fetchMapDescription() {
            ky.get(process.env.VUE_APP_API_BASE_URL + 'tags?filter[name]=map-description-label')
                .json()
                .then(data => {
                    this.mapDescription = data.data[0]?.attributes?.description?.value;
                }).catch(error => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                });
        },
    },
};
</script>

<style module lang="scss">
    .page {
        height: 100%;
        position: relative;
        width: 100%;
    }

    .description {
        background: white;
        border-bottom: 1px solid #E7E7E7;
        padding: 1.5rem;
    }

    .description > :first-child {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        margin-top: 0;
    }

    .description > :last-child {
        margin-bottom: 0;
        margin-top: 0.5rem;
    }

    .map {
        z-index: 1;
    }
</style>
