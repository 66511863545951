import Vue from 'vue';
import VTooltip from 'v-tooltip';
import tooltip from './assets/styles/tooltip.module.scss';

Vue.use(VTooltip, {
    defaultClass: '',
    defaultTargetClass: '',
    defaultLoadingClass: '',
    defaultTemplate: `<div class="${tooltip['tooltip']}" role="tooltip"><div class="${tooltip['tooltip-arrow']}"></div><div class="${tooltip['tooltip-inner']}"></div></div>`,
    defaultArrowSelector: `.${tooltip['tooltip-arrow']}`,
    defaultInnerSelector: `.${tooltip['tooltip-inner']}`,
});
